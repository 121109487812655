<template>
  <div class="flex gap-3">
    <div>
      <div class="w-64 mb-3 card">
        <div class="card-body">
          <div class="mb-3 text-xs font-semibold">Settings</div>

          <div class="mb-3">
            <label>Payout System</label>

            <label class="mt-1 font-medium">
              <input type="radio" value="standard" v-model="payoutSystem" />
              Standard
            </label>

            <label class="mt-1 font-medium">
              <input type="radio" value="points" v-model="payoutSystem" />
              Points
            </label>
          </div>

          <div class="mb-3">
            <label>Parent Choice</label>

            <label class="mt-1 font-medium">
              <input type="radio" value="default" v-model="parentStrategy" />
              Default
            </label>

            <label class="mt-1 font-medium">
              <input type="radio" value="random" v-model="parentStrategy" />
              Random
            </label>
          </div>

          <div class="mb-3">
            <label>Standard Pkg Price</label>
            <input
              type="text"
              v-model="standardPackagePrice"
              class="form-control" />
          </div>

          <div class="mb-3">
            <label>Standard Pkg Cost</label>
            <input
              type="text"
              v-model="standardPackageProductionCost"
              class="form-control" />
          </div>

          <div class="mb-3">
            <label>Standard Pair Payout</label>
            <input
              type="text"
              v-model="standardPairPayout"
              class="form-control" />
          </div>

          <div class="mb-3">
            <label>VIP Pkg Price</label>
            <input type="text" v-model="vipPackagePrice" class="form-control" />
          </div>

          <div class="mb-3">
            <label>VIP Pkg Cost</label>
            <input
              type="text"
              v-model="vipPackageProductionCost"
              class="form-control" />
          </div>

          <div class="mb-3">
            <label>VIP Pair Payout</label>
            <input type="text" v-model="vipPairPayout" class="form-control" />
          </div>

          <div class="mb-3">
            <label>Flush Percent</label>
            <input type="text" v-model="flushPercent" class="form-control" />
          </div>

          <div class="mb-3">
            <label>Rank Percentage Multipliers</label>
            <div v-for="n in 10" :key="n">
              <span class="mr-1 text-xs">{{ n }}:</span>
              <input
                type="text"
                v-model="rankPercentage[n - 1]"
                class="w-12 form-control" />
              <span class="ml-3 mr-1 text-sm">Share:</span>
              <input
                type="text"
                v-model="rankPercentageShare[n - 1]"
                class="w-12 form-control" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="w-64 mb-3 card">
        <div class="card-body">
          <div class="text-xs font-semibold">Results</div>

          <table class="text-xs">
            <tbody>
              <tr>
                <td>Standard:</td>
                <td class="text-right">{{ standardCount }}</td>
              </tr>
              <tr>
                <td>Standard Rev:</td>
                <td class="text-right">
                  {{ aed(standardPackageRevenue) }}
                </td>
              </tr>
              <tr>
                <td>VIP:</td>
                <td class="text-right">{{ vipCount }}</td>
              </tr>
              <tr>
                <td>VIP Rev:</td>
                <td class="text-right">{{ aed(vipPackageRevenue) }}</td>
              </tr>
              <tr>
                <td>Total Rev:</td>
                <td class="text-right">
                  {{ aed(totalRevenue) }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="mt-3 text-xs font-semibold">Referral Rewards (max)</div>
          <table class="text-xs">
            <tbody>
              <tr>
                <td>Standard Rewards:</td>
                <td class="text-right">{{ aed(standardReferralRewards) }}</td>
              </tr>
              <tr>
                <td>VIP Rewards:</td>
                <td class="text-right">{{ aed(vipReferralRewards) }}</td>
              </tr>
              <tr>
                <td>Store Credit</td>
                <td class="text-right">{{ aed(storeCreditRewards) }}</td>
              </tr>
              <tr>
                <td>Total</td>
                <td class="text-right">{{ aed(totalRewards) }}</td>
              </tr>
            </tbody>
          </table>
          <div class="mt-3 text-xs font-semibold">Binary</div>

          <table class="text-xs">
            <tbody>
              <tr>
                <td>Total Pairs:</td>
                <td class="text-right">{{ pairCount }}</td>
              </tr>

              <tr v-if="payoutSystem == 'standard'">
                <td>Standard Pairs:</td>
                <td class="text-right">{{ standardPairCount }}</td>
              </tr>

              <tr v-if="payoutSystem == 'standard'">
                <td>VIP Pairs:</td>
                <td class="text-right">{{ vipPairCount }}</td>
              </tr>

              <tr>
                <td>Total Payout:</td>
                <td class="text-right">{{ aed(totalPayout) }}</td>
              </tr>

              <tr>
                <td>After Exec:</td>
                <td class="text-right">
                  {{ aed(Math.round(totalPayoutAfterExecutive)) }}
                </td>
              </tr>

              <tr>
                <td>Exec+Flush:</td>
                <td class="text-right">
                  {{ aed(payoutAfterModifiers) }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="mt-3 text-xs font-semibold">Profit</div>

          <table class="text-xs">
            <tbody>
              <tr>
                <td>Grand Total Rewards</td>
                <td class="text-right">
                  {{ aed(totalRewards + payoutAfterModifiers) }}
                </td>
              </tr>

              <tr>
                <td>Grand Total Prod Cost</td>
                <td class="text-right">
                  {{ aed(totalProductionCost) }}
                </td>
              </tr>

              <tr>
                <td>Reward %</td>
                <td class="text-right">
                  <span v-if="totalRevenue > 0">{{
                    (
                      ((totalRewards + payoutAfterModifiers) * 100) /
                      totalRevenue
                    ).toFixed(2)
                  }}</span>
                </td>
              </tr>

              <tr>
                <td>Profit</td>
                <td class="text-right">
                  {{
                    aed(
                      totalRevenue -
                        totalProductionCost -
                        totalRewards -
                        payoutAfterModifiers
                    )
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div>
      <div class="w-64 mb-3 card">
        <div class="card-body">
          <div class="mb-1 text-xs font-semibold">
            Queue: {{ queueIndex }} processed,
            {{ childQueue.length - queueIndex }} remaining
            <br />
            {{ treeSize }} nodes
          </div>
          <input
            type="text"
            v-model="nToAdd"
            class="inline-block w-16 form-control" />
          <button
            type="button"
            class="m-1 btn btn-sm btn-dark"
            @click="addChildrenFromQueue()">
            Add
          </button>
          <button
            type="button"
            class="m-1 btn btn-sm btn-danger"
            @click="resetState()">
            Reset
          </button>
          <button
            type="button"
            class="m-1 btn btn-sm btn-dark"
            @click="drawGraph()">
            Draw Graph
          </button>
          <button
            type="button"
            class="m-1 btn btn-sm btn-dark"
            @click="populateLegacyData()">
            Fill Legacy Tree
          </button>
          <button
            type="button"
            class="m-1 btn btn-sm btn-dark"
            @click="addToQueue()">
            Add to Queue
          </button>
          <br />
          <br />
          <div>
            <input type="text" class="form-control" v-model="bridgeID" />
            <button
              type="button"
              class="m-1 btn btn-sm btn-dark"
              @click="calcBridgePayout()">
              Fetch Payout
            </button>
            <div>{{ aed(bridgePayout) }}</div>
          </div>
          <!-- <div style="max-height: 300px; overflow-y: auto">
            <table class="mt-1 text-xs">
              <thead>
                <tr>
                  <th></th>
                  <th>Date</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(child, i) in childQueue"
                  :key="i"
                  v-bind:class="{
                    'opacity-50': child.added,
                    [child.classes ?? '']: true,
                  }"
                >
                  <td class="p-0">{{ i }}</td>
                  <td class="p-0">{{ child.start_date }}</td>
                  <td class="p-0">{{ child.membership_type }}</td>
                </tr>
              </tbody>
            </table>
          </div> -->
        </div>
      </div>
      <div class="w-64 mb-3 card">
        <div class="card-body" style="max-height: 300px; overflow-y: auto">
          <div class="text-xs font-semibold">Log</div>
          <div v-for="(logEntry, i) in log" class="text-xs">
            {{ logEntry }}
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="mb-3 w-96 card">
        <div class="p-1 card-body">
          <div class="mb-3 text-xs font-semibold">Pairs/Payouts</div>

          <table class="text-sm table-sm">
            <thead>
              <tr>
                <td>Name</td>
                <td class="text-right">Pairs</td>
                <td class="text-right">Payout</td>
                <td class="text-right">Rem Left</td>
                <td class="text-right">Rem Right</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(summary, name, i) in pairSummaries" :key="i">
                <td class="text-xs">{{ name }}</td>
                <td class="text-right">{{ summary.pairs }}</td>
                <td class="text-right">{{ summary.payout }}</td>
                <td class="text-right">
                  {{ leftPending(summary.id) }}
                </td>
                <td class="text-right">
                  {{ rightPending(summary.id) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const aedFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "AED",
});

window.waitingToPair = {};

export default {
  data: function () {
    this.tree = {
      nodes: [],
      edges: [],
    };
    this.initialData = {};
    this.pairSummaries = {};
    this.tempLog = [];
    this.bridgeMap = {};
    return {
      payoutSystem: "points",
      parentStrategy: "default",
      flushPercent: 20,
      standardPackagePrice: 322,
      standardPackageProductionCost: 75,
      standardPairPayout: 12,
      vipPackagePrice: 1600,
      vipPackageProductionCost: 250,
      vipPairPayout: 60,
      rankPercentage: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      rankPercentageShare: [30, 19, 13, 11, 11, 14, 0, 0.7, 0, 0],
      childQueue: [],
      queueIndex: 0,
      nToAdd: 10,
      log: [],
      pairs: [],
      standardCount: 0,
      vipCount: 0,
      treeSize: 0,
      bridgeID: 122,
      bridgePayout: 0,
    };
  },
  computed: {
    totalProductionCost() {
      return (
        this.standardCount * this.standardPackageProductionCost +
        this.vipCount * this.vipPackageProductionCost
      );
    },
    payoutAfterModifiers() {
      return (
        (this.totalPayoutAfterExecutive * (100 - parseInt(this.flushPercent))) /
        100
      );
    },
    standardPackageRevenue() {
      return this.standardCount * this.standardPackagePrice;
    },
    vipPackageRevenue() {
      return this.vipCount * this.vipPackagePrice;
    },
    totalRevenue() {
      return this.standardPackageRevenue + this.vipPackageRevenue;
    },
    standardReferralRewards() {
      return this.standardCount * 25;
    },
    vipReferralRewards() {
      return this.vipCount * 100;
    },
    storeCreditRewards() {
      return this.vipCount * 0;
    },
    totalRewards() {
      return (
        this.standardReferralRewards +
        this.vipReferralRewards +
        this.storeCreditRewards
      );
    },
    totalPayout() {
      return this.pairs.reduce((acc, pair) => acc + pair.pair_value, 0);
    },
    pairCount() {
      return this.pairs.length;
    },
    standardPairCount() {
      return this.pairs.filter((pair) => pair.pair_type == "standard").length;
    },
    vipPairCount() {
      return this.pairs.filter((pair) => pair.pair_type == "vip").length;
    },
    totalVipPairPayout() {
      if (this.payoutSystem == "standard") {
        return this.pairs
          .filter((pair) => pair.pair_type == "vip")
          .reduce((acc, pair) => acc + pair.pair_value, 0);
      } else {
        return this.totalPayout;
      }
    },
    totalStandardPairPayout() {
      return this.pairs
        .filter((pair) => pair.pair_type == "standard")
        .reduce((acc, pair) => acc + pair.pair_value, 0);
    },
    totalPayoutAfterExecutive() {
      let result = this.totalPayout;

      for (n = 0; n < 10; n++) {
        result +=
          (parseInt(this.rankPercentageShare[n]) / 100) *
          (parseInt(this.rankPercentage[n]) / 100) *
          this.totalPayout;
      }

      return result;
    },
  },
  methods: {
    leftPending(id) {
      return this.payoutSystem == "standard"
        ? window.waitingToPair[id]?.left?.length ?? 0
        : window.waitingToPair[id]?.left ?? 0;
    },
    rightPending(id) {
      return this.payoutSystem == "standard"
        ? window.waitingToPair[id]?.right?.length ?? 0
        : window.waitingToPair[id]?.right ?? 0;
    },
    updatePairSummaries() {
      this.pairSummaries = {};

      this.pairs.forEach((x) => {
        this.pairSummaries[this.bridgeMap[x.bridge]] ??= {
          pairs: 0,
          payout: 0,
          id: x.bridge,
        };
        this.pairSummaries[this.bridgeMap[x.bridge]].pairs++;
        this.pairSummaries[this.bridgeMap[x.bridge]].payout += x.pair_value;
      });
    },
    calcBridgePayout() {
      let result = 0;
      this.pairs.forEach((pair) => {
        if (pair.bridge == this.bridgeID) {
          result += pair.pair_value;
        }
      });

      this.bridgePayout = result;
    },
    aed(value) {
      return aedFormatter.format(value);
    },
    addChild(parentID, childID, direction, goDeeper = false) {
      let parent = this.tree.nodes.find((node) => node.data.id == parentID);
      let existingChildren = this.tree.edges.filter(
        (edge) => edge.data.source == parentID
      );

      if (
        existingChildren.length == 2 ||
        existingChildren.find((edge) => edge.data.direction == direction)
      ) {
        if (goDeeper) {
          const newParentID = existingChildren.find(
            (edge) => edge.data.direction == direction
          ).data.target;
          return this.addChild(newParentID, childID, direction, goDeeper);
        } else {
          return null;
        }
      } else {
        const newEdge = {
          data: {
            source: parentID,
            target: childID,
            direction: direction,
          },
        };
        if (parentID == childID) {
          return;
        }
        this.tree.edges.push(newEdge);

        return newEdge;
      }
    },
    findPairs(edge) {
      if (this.payoutSystem == "standard") {
        this.findStandardPairs(edge);
      } else {
        this.findPointsPairs(edge);
      }
    },
    findPointsPairs(edge) {
      let pairDirection = edge.data.direction == "left" ? "right" : "left";

      let bridgeID = edge.data.source;
      const pair1 = this.tree.nodes.find(
        (node) => node.data.id == edge.data.target
      );
      if (!pair1) {
        throw new Error("Pair 1 not found");
      }
      while (bridgeID) {
        this.findPointsPairsAtBridge(bridgeID, pair1, pairDirection);
        let newEdge = this.tree.edges.find(
          (edge) => edge.data.target == bridgeID
        );
        bridgeID = newEdge?.data?.source;
        pairDirection = newEdge?.data?.direction == "left" ? "right" : "left";
      }
    },
    findPointsPairsAtBridge(bridgeID, pair1, pairDirection) {
      waitingToPair[bridgeID] ??= {};
      const srcDirection = pairDirection == "left" ? "right" : "left";
      waitingToPair[bridgeID][pairDirection] ??= 0;
      waitingToPair[bridgeID][srcDirection] ??= 0;

      const waitingToPairPoints = waitingToPair[bridgeID][pairDirection];

      if (waitingToPairPoints == 0) {
        waitingToPair[bridgeID][srcDirection] += pair1.data.points;
      } else if (pair1.data.points >= waitingToPairPoints) {
        this.pairs.push({
          bridge: bridgeID,
          pair_value: 2 * waitingToPairPoints,
        });
        waitingToPair[bridgeID][srcDirection] =
          pair1.data.points - waitingToPairPoints;
        waitingToPair[bridgeID][pairDirection] = 0;
      } else {
        this.pairs.push({
          bridge: bridgeID,
          pair_value: 2 * pair1.data.points,
        });
        waitingToPair[bridgeID][pairDirection] =
          waitingToPairPoints - pair1.data.points;
      }
    },
    findStandardPairs(edge) {
      let pairDirection = edge.data.direction == "left" ? "right" : "left";

      let bridgeID = edge.data.source;
      const pair1 = this.tree.nodes.find(
        (node) => node.data.id == edge.data.target
      );
      if (!pair1) {
        throw new Error("Pair 1 not found");
      }
      while (bridgeID) {
        this.findStandardPairsAtBridge(bridgeID, pair1, pairDirection);
        let newEdge = this.tree.edges.find(
          (edge) => edge.data.target == bridgeID
        );
        bridgeID = newEdge?.data?.source;
        pairDirection = newEdge?.data?.direction == "left" ? "right" : "left";
      }
    },
    findStandardPairsAtBridge(bridgeID, pair1, pairDirection) {
      const waitingToPairList =
        waitingToPair?.[bridgeID]?.[pairDirection] ?? [];

      const pair2Index = waitingToPairList.findIndex((node) => {
        return node.data.membership_type == pair1.data.membership_type;
      });
      if (pair2Index > -1) {
        const pair2 = waitingToPairList[pair2Index];
        waitingToPairList.splice(pair2Index, 1);

        this.pairs.push({
          pair1: pair1.data.id,
          pair2: pair2.data.id,
          bridge: bridgeID,
          pair_type: pair1.data.membership_type,
          pair_value:
            pair1.data.membership_type == "vip"
              ? parseInt(this.vipPairPayout) * 2
              : parseInt(this.standardPairPayout) * 2,
        });
      } else {
        const srcDirection = pairDirection == "left" ? "right" : "left";
        waitingToPair[bridgeID] ||= {};
        waitingToPair[bridgeID][srcDirection] ||= [];
        waitingToPair[bridgeID][srcDirection].push(pair1);
      }
    },
    resetState() {
      this.tree = {
        nodes: [],
        edges: [],
      };
      this.childQueue.forEach((x) => (x.added = false));
      this.queueIndex = 0;
      this.log = [];
      this.tempLog = [];
      this.pairs = [];
      this.standardCount = 0;
      this.vipCount = 0;
      this.treeSize = 0;
      waitingToPair = {};
    },
    populateLegacyData() {
      let newNodes = [];
      let newLinks = [];

      newNodes.push({
        data: {
          id: this.initialData.legacy_links.root_customer.id,
          name: this.initialData.legacy_links.root_customer.full_name,
        },
      });

      this.initialData.legacy_links.links.forEach((link) => {
        newNodes.push({
          data: {
            id: link.child_id,
            name: link.full_name,
            legacy: true,
          },
        });

        newLinks.push({
          data: {
            source: link.parent_id,
            target: link.child_id,
            direction: link.direction,
          },
        });
      });

      newNodes.forEach((x) => {
        this.bridgeMap[x.data.id] = x.data.name;
      });

      this.tree.nodes = newNodes;
      this.tree.edges = newLinks;
      this.treeSize = this.tree.nodes.length;
    },
    drawGraph() {},
    fetchLegacyLinks() {
      axios
        .get("/admin/members/binary_network/simulator_legacy_links")
        .then((response) => {
          this.initialData["legacy_links"] = response.data;

          this.tree.nodes.push({
            data: {
              id: this.initialData.legacy_links.root_customer.id,
              name: this.initialData.legacy_links.root_customer.full_name,
            },
          });
          document.getElementById("graph-status").innerHTML = "Data loaded!";
        });
    },
    fetchNewPackages() {
      axios
        .get("/admin/members/binary_network/simulator_new_packages")
        .then((response) => {
          this.childQueue = response.data.packages;
        });
    },
    addToQueue() {
      const n = parseInt(prompt("How many to add?"));

      for (let i = 0; i < n; i++) {
        const memType = Math.random() < 0.7 ? "vip" : "standard";
        this.childQueue.push({
          membership_type: memType,
        });
      }
    },
    addChildrenFromQueue() {
      // Add up to n children from the queue to the tree
      let n = parseInt(this.nToAdd);
      while (this.queueIndex < this.childQueue.length && n > 0) {
        const child = this.childQueue[this.queueIndex];
        const newNodeData = {
          data: {
            id: `${child.membership_type[0]}${this.queueIndex}`,
            name: `${child.membership_type[0]}${this.queueIndex}`,
            membership_type: child.membership_type,
            points:
              child.membership_type == "vip"
                ? parseInt(this.vipPairPayout)
                : parseInt(this.standardPairPayout),
          },
        };
        this.bridgeMap[newNodeData.data.id] = newNodeData.data.name;
        this.tree.nodes.push(newNodeData);

        if (child.membership_type == "vip") {
          this.vipCount++;
        } else {
          this.standardCount++;
        }

        let parent = null;

        if (this.parentStrategy == "default" && child.parent_id) {
          parent = this.tree.nodes.find(
            (node) => node.data.id == child.parent_id
          );
        }

        if (!parent || this.parentStrategy == "random") {
          if (this.parentStrategy == "default") {
            child.classes = "text-red-600";
          }

          // choose a random element from nodes
          do {
            parent =
              this.tree.nodes[
                Math.floor(Math.random() * this.tree.nodes.length)
              ];
          } while (
            parent.data.id == `${child.membership_type[0]}${this.queueIndex}`
          );
        }

        if (parent) {
          const direction = Math.random() < 0.5 ? "left" : "right";
          const newEdge = this.addChild(
            parent.data.id,
            newNodeData.data.id,
            direction,
            true
          );
          if (newEdge) {
            this.findPairs(newEdge);
            child.added = true;
          } else {
            console.log("Couldn't add child", child, parent, newEdge);
          }
        }

        n--;
        this.queueIndex++;
      }

      // this.log = this.tempLog;
      this.treeSize = this.tree.nodes.length;

      this.updatePairSummaries();
    },
  },
  mounted() {
    this.fetchLegacyLinks();
    this.fetchNewPackages();
  },
};
</script>
