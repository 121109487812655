<template>
  <div>
    <div v-if="!error">
      <h2 class="inline-block secondary-header">Executive Rank Requirements</h2>
      <a
        href="/account/membership-packages/executive_bonuses"
        class="ml-3 text-sm">
        Learn more
      </a>

      <div class="card" :class="{ 'opacity-30': loading }">
        <div class="flex gap-1">
          <div class="w-10 p-2 text-center" v-on:click="prevRank()">
            <div v-show="current_rank > 1">
              <i
                class="text-lg text-blue-500 cursor-pointer fa fa-arrow-circle-left"></i>
            </div>
          </div>
          <div class="flex-grow p-2 font-bold text-center">
            <div v-if="current_rank">
              {{ rankNames[current_rank] }}
              <i class="mx-1 text-sm fa fa-arrow-right"></i>
              {{ rankNames[current_rank + 1] }}
            </div>
          </div>
          <div class="w-10 p-2 text-center" v-on:click="nextRank()">
            <div v-show="current_rank < 8">
              <i
                class="text-lg text-blue-500 cursor-pointer fa fa-arrow-circle-right"></i>
            </div>
          </div>
        </div>
        <div class="whitespace-nowrap">
          <div
            class="flex px-2 py-1"
            :class="{
              'text-green-600 dark:text-green-400':
                current_activity > next_rank_min_activity,
            }">
            <div class="text-base font-medium">Minimum Network Activity</div>
            <div class="flex-grow text-sm text-right">
              {{ activityProgressPercentage }}
            </div>
          </div>
          <div
            class="p-2 pt-0 border-b"
            :class="{
              'text-green-600 dark:text-green-400':
                current_activity > next_rank_min_activity,
            }">
            <div>
              <div class="w-full h-2 bg-gray-200 rounded-full dark:bg-gray-800">
                <div
                  class="h-full transition-all duration-700 rounded-full bg-lime-500"
                  :style="`width: ${activityProgressPercentage}`"></div>
              </div>
              <div class="my-1 text-sm text-center font-oswald">
                {{ aedFormatter(current_activity) }} /
                {{ aedFormatter(next_rank_min_activity) }}
              </div>
            </div>
          </div>
          <div class="px-2 py-1">
            <div class="text-base font-medium">Ranked Direct Referrals</div>
          </div>
          <div class="px-2 border-b">
            <div
              class="py-1"
              :class="{
                'text-green-600 dark:text-green-400': rankRequirementsMet,
              }"
              v-for="(rank_details, i) in next_rank_ranked_referrals"
              :key="i">
              <div class="overflow-hidden">
                <span v-if="rank_details.customer_id">
                  <i class="text-green-500 fa-regular fa-circle-check"></i>
                </span>
                <span v-else>
                  <i class="text-zinc-500 fa-regular fa-circle"></i>
                </span>

                <span class="ml-2">
                  {{ rankNames[rank_details.required_rank] }}
                </span>
                <span v-if="rank_details.customer_id">
                  - {{ rank_details.customer_name }}
                </span>
              </div>
            </div>
          </div>
          <div
            class="flex px-2 py-1"
            :class="{
              'text-green-600 dark:text-green-400':
                current_mpv > next_rank_min_mpv,
            }">
            <div class="text-base font-medium">Monthly Purchase Volume</div>
            <div class="flex-grow text-sm text-right">
              {{ mpvProgressPercentage }}
            </div>
          </div>
          <div
            class="px-2 py-1"
            :class="{
              'text-green-600 dark:text-green-400':
                current_mpv > next_rank_min_mpv,
            }">
            <div>
              <div class="w-full h-2 bg-gray-200 rounded-full dark:bg-gray-800">
                <div
                  class="h-full transition-all duration-700 rounded-full bg-lime-500"
                  :style="`width: ${mpvProgressPercentage}`"></div>
              </div>
              <div class="my-1 text-sm text-center font-oswald">
                {{ aedFormatter(current_mpv) }} /
                {{ aedFormatter(next_rank_min_mpv) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

const aedFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "AED",
});

export default {
  name: "ExecutiveRankRequirements",
  data: function () {
    return {
      loading: true,
      error: false,
      current_rank: null,
      next_rank_min_activity: null,
      next_rank_ranked_referrals: {},
      next_rank_min_mpv: null,
      current_activity: null,
      current_mpv: null,
      rankNames: {
        1: "Silver 1",
        2: "Silver 2",
        3: "Silver 3",
        4: "Gold 1",
        5: "Gold 2",
        6: "Gold 3",
        7: "Platinum 1",
        8: "Platinum 2",
        9: "Platinum 3",
      },
      dark_mode: false,
      successGreen: "#a3e635",
    };
  },
  computed: {
    activityProgressPercentage() {
      if (!this.next_rank_min_activity || !this.current_activity) {
        return "0%";
      }

      let percentage =
        (this.current_activity / this.next_rank_min_activity) * 100;

      if (percentage >= 100) {
        percentage = 100;
      }

      return percentage.toFixed(2) + "%";
    },
    mpvProgressPercentage() {
      if (!this.next_rank_min_mpv || !this.current_mpv) {
        return "0%";
      }

      let percentage = (this.current_mpv / this.next_rank_min_mpv) * 100;

      if (percentage >= 100) {
        percentage = 100;
      }

      return percentage.toFixed(2) + "%";
    },
    rankRequirementsMet() {
      if (Object.keys(this.next_rank_ranked_referrals).length === 0) {
        return true;
      }

      return this.next_rank_ranked_referrals.every((rank_details) => {
        return rank_details.customer_id;
      });
    },
  },
  methods: {
    aedFormatter(amount) {
      return aedFormatter.format(amount);
    },
    nextRank() {
      if (this.current_rank < 8) {
        this.current_rank++;
        this.getRankRequirements();
      }
    },
    prevRank() {
      if (this.current_rank > 1) {
        this.current_rank--;
        this.getRankRequirements();
      }
    },
    getRankRequirements() {
      this.loading = true;
      axios
        .get(
          `/account/executive-rank-requirements?rank=${
            this.current_rank ? this.current_rank : ""
          }`
        )
        .then((response) => {
          this.current_rank = response.data.current_rank;
          this.next_rank_min_activity = response.data.next_rank_min_activity;
          this.next_rank_ranked_referrals =
            response.data.next_rank_ranked_referrals;
          this.next_rank_min_mpv = response.data.next_rank_min_mpv;
          this.current_activity = response.data.current_activity;
          this.current_mpv = response.data.current_mpv;
        })
        .catch((error) => {
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getRankRequirements();
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      this.dark_mode = true;
      this.successGreen = "#43a047";
    }
  },
};
</script>
