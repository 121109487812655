export default [
  {
    name: "Burberry",
    slug: "burberry",
    perfumes: [
      {name: "Weekend Burberry", image: "weekend_burberry.jpg"}
    ]
  },
  {
    name: "Bvlgari",
    slug: "bvlgari",
    perfumes: [
      {name: "Omnia Amethyste", image: "omnia_amethyste.jpg"}
    ]
  },
  {
    name: "Carolina Herrera",
    slug: "carolina_herrera",
    perfumes: [
      {name: "212 VIP", image: "212_vip.jpg"}
    ]
  },
  {
    name: "Chanel",
    slug: "chanel",
    perfumes: [
      {name: "Allure Homme Sport", image: "allure_homme_sport.jpg"},
      {name: "Bleu de Chanel", image: "bleu_de_chanel.jpg"},
      {name: "Chance", image: "chance.jpg"},
      {name: "Chanel No. 5", image: "chanel_no_5.jpg"},
      {name: "Coco Mademoiselle", image: "coco_mademoiselle.jpg"},
      {name: "Egoiste Platinum", image: "egoiste_platinum.jpg"},
      {name: "Gabrielle Chanel", image: "gabrielle_chanel.jpg"}
    ]
  },
  {
    name: "Christian Dior",
    slug: "christian_dior",
    perfumes: [
      {name: "J'adore", image: "jadore.jpg"},
      {name: "Lucky Dior", image: "lucky_dior.jpg"},
      {name: "Poison Girl", image: "poison_girl.jpg"},
      {name: "Sauvage", image: "sauvage.jpg"}

    ]
  },
  {
    name: "Clinique",
    slug: "clinique",
    perfumes: [
      {name: "Clinique Happy", image: "happy.jpg"}
    ]
  },
  {
    name: "Creed",
    slug: "creed",
    perfumes: [
      {name: "Aventus Creed", image: "aventus.jpg"}
    ]
  },
  {
    name: "Dolce & Gabbana",
    slug: "dolce_gabbana",
    perfumes: [
      {name: "K", image: "k.jpg"}
    ]
  },
  {
    name: "Etienne Aigner",
    slug: "etienne_aigner",
    perfumes: [
      {name: "Clear Day", image: "clear_day.png"}
    ]
  },
  {
    name: "Giorgio Armani",
    slug: "giorgio_armani",
    perfumes: [
      {name: "Aqua Di Gio", image: "aqua_di_gio.jpg"},
      {name: "Armani Mania", image: "armani_mania.png"}
    ]
  },
  {
    name: "Gucci",
    slug: "gucci",
    perfumes: [
      {name: "Gucci Rush", image: "rush.jpg"}
    ]
  },
  {
    name: "Guerlain",
    slug: "guerlain",
    perfumes: [
      {name: "L'Homme Idéal L'Intense", image: "ideal_intense.jpg"}
    ]
  },
  {
    name: "Hugo Boss",
    slug: "hugo_boss",
    perfumes: [
      {name: "Boss Bottled Oud Saffron", image: "oud_saffron.jpg"},
      {name: "Boss The Scent For Her", image: "scent_for_her.jpg"}
    ]
  },
  {
    name: "Jean Paul Gaultier",
    slug: "jean_paul_gaultier",
    perfumes: [
      {name: "Scandal A Paris", image: "scandal_paris.jpg"}
    ]
  },
  {
    name: "Jo Malone London",
    slug: "jo_malone_london",
    perfumes: [
      {name: "English Pear & Freesia", image: "pear_freesia.jpg"}
    ]
  },
  {
    name: "Lancome",
    slug: "lancome",
    perfumes: [
      {name: "La Nuit Tresor", image: "la_nuit_tresor.jpg"},
      {name: "La Vie Est Belle", image: "la_vie_est_belle.jpg"}
    ]
  },
  {
    name: "Lorenzo Villoresi",
    slug: "lorenzo_villoresi",
    perfumes: [
      {name: "Teint de Neige Lorenzo Villoresi", image: "teint_neige.jpg"}
    ]
  },
  {
    name: "Louis Vuitton",
    slug: "louis_vuitton",
    perfumes: [
      {name: "L'Immensite", image: "limmensite.jpg"}
    ]
  },
  {
    name: "Maison Martin Margiela",
    slug: "maison_martin_margiela",
    perfumes: [
      {name: "At The Barber's", image: "at_the_barbers.jpg"}
    ]
  },
  {
    name: "Montale",
    slug: "montale",
    perfumes: [
      {name: "Roses Musk by Montale", image: "roses_musk.jpg"}
    ]
  },
  {
    name: "Nasomatto",
    slug: "nasomatto",
    perfumes: [
      {name: "Black Afgano", image: "black_afgano.jpg"}
    ]
  },
  {
    name: "Paco Rabanne",
    slug: "paco_rabanne",
    perfumes: [
      {name: "1 Million", image: "1_million.jpg"},
      {name: "Black XS L'Exces", image: "black_xs_lexces.jpg"},
      {name: "Invictus", image: "invictus.jpg"},
      {name: "Lady Million", image: "lady_million.jpg"},
      {name: "Pure XS Night", image: "pure_xs_night.jpg"}

    ]
  },
  {
    name: "Ralph Lauren",
    slug: "ralph_lauren",
    perfumes: [
      {name: "Polo Blue", image: "polo_blue.jpg"},
      {name: "Polo Sport", image: "polo_sport.jpg"}
    ]
  },
  {
    name: "Shiseido",
    slug: "shiseido",
    perfumes: [
      {name: "Zen Shiseido", image: "zen.jpg"}
    ]
  },
  {
    name: "Thameen",
    slug: "thameen",
    perfumes: [
      {name: "Carved Oud Thameen", image: "carved_oud.jpg"}
    ]
  },
  {
    name: "Tom Ford",
    slug: "tom_ford",
    perfumes: [
      {name: "Black Orchid", image: "black_orchid.jpg"},
      {name: "F*cking Fabulous", image: "fabulous.jpg"},
      {name: "Ombré Leather", image: "ombre_leather.jpg"}
    ]
  },
  {
    name: "Versace",
    slug: "versace",
    perfumes: [
      {name: "Bright Crystal", image: "bright_crystal.jpg"}
    ]
  },
  {
    name: "Yves Saint Laurent",
    slug: "yves_saint_laurent",
    perfumes: [
      {name: "Black Opium", image: "black_opium.jpg"},
      {name: "Mon Paris", image: "mon_paris.jpg"},
      {name: "Supreme Bouquet", image: "supreme_bouquet.jpg"}
    ]
  }
]
