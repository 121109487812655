import { createApp } from "vue";
import BinaryTreeDisplay from "../components/binary/tree_display.vue";
import NetworkSimulator from "../components/binary/network_simulator.vue";

window.initializeBinaryGraph = function (
  containerSelector,
  rootCustomerID,
  routePrefix
) {
  createApp(BinaryTreeDisplay, {
    rootCustomerID: rootCustomerID,
    routePrefix: routePrefix,
  }).mount(containerSelector);
};

window.initializeNetworkSimulator = function (containerSelector) {
  createApp(NetworkSimulator).mount(containerSelector);
};
