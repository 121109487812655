import ForceGraph from "force-graph";
import axios from "axios";

const treeCache = {};
const nodeSeparator = 20;
const rankSeparator = 30;
let rootNodeID = null;

function prepareLayout(graphData) {
  graphData.nodes.forEach((node) => {
    treeCache[node.id] ||= {
      left: null,
      right: null,
      width: 0,
    };

    treeCache[node.id].direction = node.direction;
    treeCache[node.id].parent_id = node.parent_id;

    if (node.parent_id) {
      treeCache[node.parent_id] ||= {
        left: null,
        right: null,
        width: 0,
      };
      treeCache[node.parent_id][node.direction] = node.id;
    } else {
      rootNodeID = node.id;
    }

    node.depth = node.ancestor_ids.length;
  });

  // setWidthForNode(rootNodeID);
  // setXForNode(rootNodeID);
  if (rootNodeID == null) {
    return;
  }

  if (treeCache[rootNodeID]["left"]) {
    squeezeRight(treeCache[rootNodeID]["left"], 0);
  }

  if (treeCache[rootNodeID]["right"]) {
    squeezeLeft(treeCache[rootNodeID]["right"], 0);
  }
}

function squeezeLeft(nodeID, higherNodeX): number {
  // If this node has no children on the left, then it is restricted by
  // higherNodeX, otherwise it's leftmost child is restricted by higherNodeX,
  // and this node is restricted by it's left child's rightmost child.

  const leftChildID = treeCache[nodeID].left;
  if (leftChildID) {
    const leftChildX = squeezeLeft(leftChildID, higherNodeX);
    const minX = leftChildX + nodeSeparator;
    treeCache[nodeID].x = minX;
  } else {
    const minX = higherNodeX + nodeSeparator;
    treeCache[nodeID].x = minX;
  }

  const rightChildID = treeCache[nodeID].right;
  if (rightChildID) {
    const rightChildX = squeezeLeft(rightChildID, treeCache[nodeID].x);
    return rightChildX;
  } else {
    return treeCache[nodeID].x;
  }
}

function squeezeRight(nodeID, higherNodeX): number {
  // If this node has no children on the right, then it is restricted by
  // higherNodeX, otherwise it's rightmost child is restricted by higherNodeX,
  // and this node is restricted by it's right child's leftmost child.

  const rightChildID = treeCache[nodeID].right;
  if (rightChildID) {
    const rightChildX = squeezeRight(rightChildID, higherNodeX);
    const maxX = rightChildX - nodeSeparator;
    treeCache[nodeID].x = maxX;
  } else {
    const maxX = higherNodeX - nodeSeparator;
    treeCache[nodeID].x = maxX;
  }

  const leftChildID = treeCache[nodeID].left;
  if (leftChildID) {
    const leftChildX = squeezeRight(leftChildID, treeCache[nodeID].x);
    return leftChildX;
  } else {
    return treeCache[nodeID].x;
  }
}

function setWidthForNode(nodeID): number {
  if (!treeCache[nodeID]) {
    return 0;
  }

  const leftID = treeCache[nodeID].left;
  const rightID = treeCache[nodeID].right;

  if (leftID && rightID) {
    treeCache[nodeID].width =
      setWidthForNode(leftID) + setWidthForNode(rightID) + nodeSeparator;
  } else if (leftID) {
    treeCache[nodeID].width = setWidthForNode(leftID);
  } else if (rightID) {
    treeCache[nodeID].width = setWidthForNode(rightID);
  } else {
    treeCache[nodeID].width = nodeSeparator / 2;
  }

  return treeCache[nodeID].width;
}

function setXForNode(nodeID, curX = 0) {
  if (!treeCache[nodeID]) return curX;

  const leftID = treeCache[nodeID].left;
  const rightID = treeCache[nodeID].right;

  if (!leftID && !rightID) {
    treeCache[nodeID].x = curX;
    return treeCache[nodeID].x;
  }

  if (leftID) {
    treeCache[nodeID].x = curX;
    setXForNode(leftID, curX - treeCache[leftID].width / 2);
  }

  if (rightID) {
    treeCache[nodeID].x = curX;
    setXForNode(rightID, curX + treeCache[rightID].width / 2);
  }
}

window["initializeCompleteBinaryGraph"] = function (containerID, routePrefix) {
  axios.get(`${routePrefix}/binary/network_complete.json`).then((response) => {
    const graphData = response.data;
    prepareLayout(graphData);

    const el = document.getElementById(containerID);

    if (el == null) {
      return null;
    }

    const Graph = ForceGraph()(el)
      .nodeId("id")
      .nodeLabel((node) => {
        return `${node["full_name"]}${node["frozen_at"] ? " (Frozen)" : ""}`;
      })
      .width(el.clientWidth)
      .height(500)
      .nodeColor((node) => {
        if (node.id == rootNodeID) {
          return "#000000";
        } else if (node["membership_type"] == "vip") {
          return node["frozen_at"] ? "#f4c969" : "#fbbf24";
        } else if (node["membership_type"] == "standard") {
          return node["frozen_at"] ? "#54a5ed" : "#248efb";
        } else {
          return "#ff0000";
        }
      })
      .cooldownTicks(0);

    graphData.nodes.forEach((node) => {
      node.y = (node.depth || 0) * rankSeparator;
      node.x = treeCache[node.id].x || 0;
    });

    Graph.graphData(graphData);
    Graph.zoomToFit(0, 225, (node) => {
      return node.id == rootNodeID;
    });
  });
};
