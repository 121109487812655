<template>
  <div>
    <form action="/store/customize-your-perfume" method="POST" enctype="multipart/form-data">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div class="p-1">
          <h2 class="mb-3">
            Create your unique perfume in just 5 quick steps!
          </h2>

          <div class="customization-step mb-3">
            <div class="font-bold text-sm uppercase">1) Customize Your Bottle <span class="font-normal">(+AED 30)</span></div>
            <span class="text-gray-500 text-sm">Upload an image to personalize your bottle and make it unique!</span>


            <input name="bottle_color" type="hidden" v-model="bottleColor" />
            <input name="cap_color" type="hidden" v-model="capColor" />
            <input name="packaging" type="hidden" v-model="packaging" />
            <input name="fragrance" type="hidden" v-model="selectedFragrance" />
            <input name="image" type="file" ref="fileInput" v-on:input="selectFile" class="my-1" accept="image/png, image/jpeg" />
          </div>

          <div class="customization-step mb-3">
            <div class="font-bold text-sm uppercase">2) Bottle Color</div>

            <button type="button" v-on:click="bottleColor = 'black'" v-bind:class="{'btn w-full md:w-auto border mr-2 mb-1': true, 'bg-blue-500 text-white': bottleColor == 'black'}">Black Matte</button>
            <button type="button" v-on:click="bottleColor = 'white'" v-bind:class="{'btn w-full md:w-auto border mr-2 mb-1': true, 'bg-blue-500 text-white': bottleColor == 'white'}">White Matte</button>
          </div>

          <div class="customization-step mb-3">
            <div class="font-bold text-sm uppercase">3) Cap Color</div>

            <button type="button" v-on:click="capColor = 'black'" v-bind:class="{'btn w-full md:w-auto border mr-2 mb-1': true, 'bg-blue-500 text-white': capColor == 'black'}">Black</button>
            <button type="button" v-on:click="capColor = 'silver'" v-bind:class="{'btn w-full md:w-auto border mr-2 mb-1': true, 'bg-blue-500 text-white': capColor == 'silver'}">Silver</button>
            <button type="button" v-on:click="capColor = 'gold'" v-bind:class="{'btn w-full md:w-auto border mr-2 mb-1': true, 'bg-blue-500 text-white': capColor == 'gold'}">Gold</button>
          </div>

          <div class="customization-step mb-3">
            <div class="font-bold text-sm uppercase">4) Packaging</div>

            <div class="flex gap-2">
              <div v-on:click="packaging = 'standard_box_white'" style="width: 150px" v-bind:class="{'border-2 cursor-pointer': true, 'border-blue-500': packaging == 'standard_box_white'}">
                <img src="https://scent-assets.s3.eu-west-3.amazonaws.com/customized_perfume/standard_box_white.jpg" style="width: 100%" />
                <div class="text-center">White Box</div>
              </div>
              <div v-on:click="packaging = 'standard_box_black'" style="width: 150px" v-bind:class="{'border-2 cursor-pointer': true, 'border-blue-500': packaging == 'standard_box_black'}">
                <img src="https://scent-assets.s3.eu-west-3.amazonaws.com/customized_perfume/standard_box_black.jpg" style="width: 100%" />
                <div class="text-center">Black Box</div>
              </div>
              <div v-on:click="packaging = 'luxe_leather_box'" style="width: 150px" v-bind:class="{'border-2 cursor-pointer': true, 'border-blue-500': packaging == 'luxe_leather_box'}">
                <img src="https://scent-assets.s3.eu-west-3.amazonaws.com/customized_perfume/luxe_leather_box.jpg" style="width: 100%" />
                <div class="text-center">Luxury Leather Box</div>
                <div class="text-center">(+AED 50)</div>
              </div>
            </div>
          </div>

          <div class="customization-step mb-3">
            <div class="font-bold text-sm uppercase">5) Fragrance</div>
            <div class="text-gray-500 text-sm">Choose from a stunning collection of inspired fragrances - high quality & high concentration guaranteed!</div>

            <button type="button" class="btn border border-black my-1" style="font-size: 1em" v-on:click="showFragranceSelector = !showFragranceSelector">
              Select Fragrance <span class="caret">▼</span>
            </button>

            <div style="width: 345px; height: 400px; padding: 5px; background: white; box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.3), 0 2px 4px -2px rgb(0 0 0 / 0.3); overflow-y: auto; position: absolute; z-index: 10;" v-if="showFragranceSelector">

              <input type="text" placeholder="Search" style="width: 90%; border: 1px solid #CCCCCC; padding: 5px;" v-model="perfumeSearch" />

              <div v-for="brand in brands">
                <div v-show="brandMatchesSearch(brand)">
                  <strong style="text-transform: uppercase; font-size: 12px">{{ brand.name }}</strong>
                  <div class="flex" style="display: flex; flex-wrap: wrap; gap: 5px; margin-bottom: 10px; padding-bottom: 5px; border-bottom: 1px #CCC solid">
                    <div v-for="perfume in brand.perfumes" style="width: 100px; border: 1px white dashed; cursor: pointer;" v-on:click="selectedFragrance = `${perfume.name} by ${brand.name}`; showFragranceSelector = false" v-show="perfumeMatchesSearch(perfume)">
                      <div v-bind:style="`width: 80px; height: 80px; margin: 10px 10px 0 10px; background-size: contain; background-position: center center; background-repeat: no-repeat; background-color: white; background-image: url(https://catalogperfumes.s3.eu-west-3.amazonaws.com/fragrance_selector/${brand.slug}/${perfume.image})`">
                      </div>
                      <div style="font-size: 12px; text-align: center; margin-top: 3px;">{{ perfume.name }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="selectedFragrance && selectedFragrance.length > 0">
              Our impression of <em>{{ selectedFragrance }}</em>
            </div>
          </div>

          <div class="mb-3">
            <div class="my-3"><span class="text-2xl font-bold">Total: AED {{price}}</span><span class="text-sm"> + VAT</span></div>
            <input type="submit" value="Add To Cart" class="btn btn-lg bg-green-600 text-white" style="min-width: 225px; text-align: center" v-bind:disabled="selectedFragrance == ''" />
          </div>
        </div>
        <div class="p-1 order-first md:order-last">
          <div class="w-1/2 mx-auto md:w-full">
            <div class="w-1/2 mx-auto md:w-full bg-no-repeat bg-contain bg-center responsive-square" v-bind:style="`background-image: url(${bottleImageUrl})`">
              <div class="bg-no-repeat bg-cover bg-center rounded-sm" v-bind:style="`position: absolute; width: 23.5%; height: 48.5%; left: 39%; top: 34.5%; background-image: url(${previewImage})`"></div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import brandData from "./data"

export default {
  components: {
  },
  props: [
  ],
  computed: {
    bottleImageUrl(){
      return `https://scent-assets.s3.eu-west-3.amazonaws.com/customized_perfume/${this.bottleColor}_${this.capColor}.jpg`
    },
    price(){
      let result = 90

      if(this.previewImage){ result += 30 }
      if(this.packaging == "luxe_leather_box"){ result += 50 }

      return result
    }
  },
  data: function(){
    return {
      bottleColor: "white",
      capColor: "gold",
      packaging: "standard_box",
      showFragranceSelector: false,
      brands: [],
      selectedFragrance: "",
      perfumeSearch: "",
      previewImage: null
    }
  },
  methods: {
    brandMatchesSearch(brand){
      if(this.perfumeSearch.length < 3){ return true }

      let q = new RegExp(this.perfumeSearch.toLowerCase(), "i")

      if(q.test(brand.name)){ return true }

      if(brand.perfumes.find((p) => q.test(p.name))){ return true }

      return false
    },
    perfumeMatchesSearch(perfume){
      if(this.perfumeSearch.length < 3){ return true }

      let q = new RegExp(this.perfumeSearch.toLowerCase(), "i")

      if(q.test(perfume.name)){ return true }

      return false
    },
    selectFile () {
      let input = this.$refs.fileInput
      let file = input.files
      if (file && file[0]) {
        let reader = new FileReader
        reader.onload = e => {
          this.previewImage = e.target.result
        }
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])
      }
    }
  },
  mounted(){
    this.brands.push(...brandData)
  }
}
</script>
