import drawGraph from "./generic_graph";
import { createApp } from "vue";
import PairingDemo1 from "../components/rewards_program/pairing_demo_1.vue";
import PairingDemo2 from "../components/rewards_program/pairing_demo_2.vue";

export default {
  init() {
    window.addEventListener("load", () => {
      drawGraph("sales-rewards-graph-container", salesRewardsGraphData);
      createApp(PairingDemo1).mount("#pairing-rewards-demo-1-container");
      createApp(PairingDemo2).mount("#pairing-rewards-demo-2-container");
    });
  },
};

const salesRewardsGraphData = {
  nodes: [
    {
      id: 1,
      label: "Alex",
      color: "#20A4F3",
      x: 0,
      y: 0,
    },
    {
      id: 2,
      label: "Bob",
      color: "#f6ae2d",
      x: -50,
      y: 50,
    },
    {
      id: 3,
      label: "Brian",
      color: "#f6ae2d",
      x: 50,
      y: 50,
    },
    {
      id: 4,
      label: "Charlie",
      color: "#f26419",
      x: 25,
      y: 100,
    },
    {
      id: 5,
      label: "Carol",
      color: "#f26419",
      x: 75,
      y: 100,
    },
    {
      id: 6,
      label: "Diana",
      color: "#55dde0",
      x: 25,
      y: 150,
    },
    {
      id: 7,
      label: "Eve",
      color: "#A06CD5",
      x: 25,
      y: 200,
    },
    {
      id: 8,
      label: "Bella",
      color: "#f6ae2d",
      x: 0,
      y: 50,
    },
  ],
  links: [
    {
      source: 1,
      target: 2,
    },
    {
      source: 1,
      target: 3,
    },
    {
      source: 3,
      target: 4,
    },
    {
      source: 3,
      target: 5,
    },
    {
      source: 4,
      target: 6,
    },
    {
      source: 6,
      target: 7,
    },
    {
      source: 1,
      target: 8,
    },
  ],
};
