import * as ActiveStorage from "@rails/activestorage";

import Rails from "@rails/ujs";
Rails.start();

import "chartkick/highcharts";

import "./public/account";
import "./admin/sorttable";
import "./admin/binary_complete";
import "./public/account_creation";
import "./admin/binary";
import "./public/main";

window.addEventListener("load", () => {
  const el = document.getElementById("complete-binary-tree-container");
  if (el) {
    initializeCompleteBinaryGraph(
      "complete-binary-tree-container",
      "/account/binary_network"
    );
  }
});
