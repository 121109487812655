window.Account = {};

Account.numberToCurrency = function (number) {
  return window.currencyPrefix + parseFloat(number).toFixed(2);
};

Account.downlineProfileModal = function (downlineID) {
  MicroModal.show("the-modal");
  document.querySelector("#the-modal .modal-container").innerHTML =
    '<div class="loader">Loading...</div>';

  fetch(`/downline/profile/${downlineID}`)
    .then((response) => response.text())
    .then((data) => {
      document.querySelector("#the-modal .modal-container").innerHTML = data;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

Account.showDownlineProfile = function () {
  Account.downlineProfileModal(this.dataset.downlineId);
};
