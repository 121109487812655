import * as d3 from "d3";

var svg = null;

function project(x, y) {
  var angle = ((x - 90) / 180) * Math.PI,
    radius = y;
  return [radius * Math.cos(angle), radius * Math.sin(angle)];
}

function handleMemberClick(d) {
  Account.downlineProfileModal(d.data.id);
}

function Tree(
  data,
  {
    // data is either tabular (array of objects) or hierarchy (nested objects)
    path, // as an alternative to id and parentId, returns an array identifier, imputing internal nodes
    id = Array.isArray(data) ? (d) => d.id : null, // if tabular data, given a d in data, returns a unique identifier (string)
    parentId = Array.isArray(data) ? (d) => d.parentId : null, // if tabular data, given a node d, returns its parent’s identifier
    children, // if hierarchical data, given a d in data, returns its children
    tree = d3.tree, // layout algorithm (typically d3.tree or d3.cluster)
    separation = tree === d3.tree
      ? (a, b) => (a.parent == b.parent ? 1 : 2) / a.depth
      : (a, b) => (a.parent == b.parent ? 1 : 2),
    sort, // how to sort nodes prior to layout (e.g., (a, b) => d3.descending(a.height, b.height))
    label, // given a node d, returns the display name
    title, // given a node d, returns its hover text
    link, // given a node d, its link (if any)
    linkTarget = "_blank", // the target attribute for links (if any)
    width = 640, // outer width, in pixels
    height = 400, // outer height, in pixels
    margin = 60, // shorthand for margins
    marginTop = margin, // top margin, in pixels
    marginRight = margin, // right margin, in pixels
    marginBottom = margin, // bottom margin, in pixels
    marginLeft = margin, // left margin, in pixels
    radius = Math.min(
      width - marginLeft - marginRight,
      height - marginTop - marginBottom
    ) / 2, // outer radius
    r = 8, // radius of nodes
    padding = 1, // horizontal padding for first and last column
    fill = "#999", // fill for nodes
    fillOpacity, // fill opacity for nodes
    stroke = "#555", // stroke for links
    strokeWidth = 1.5, // stroke width for links
    strokeOpacity = 0.4, // stroke opacity for links
    strokeLinejoin, // stroke line join for links
    strokeLinecap, // stroke line cap for links
    halo = "#fff", // color of label halo
    haloWidth = 3, // padding around the labels
  }
) {
  // If id and parentId options are specified, or the path option, use d3.stratify
  // to convert tabular data to a hierarchy; otherwise we assume that the data is
  // specified as an object {children} with nested objects (a.k.a. the “flare.json”
  // format), and use d3.hierarchy.
  const root =
    path != null
      ? d3.stratify().path(path)(data)
      : id != null || parentId != null
      ? d3.stratify().id(id).parentId(parentId)(data)
      : d3.hierarchy(data, children);

  // Sort the nodes.
  if (sort != null) root.sort(sort);

  // Compute labels and titles.
  const descendants = root.descendants();
  const L = label == null ? null : descendants.map((d) => label(d.data, d));

  // Compute the layout.
  tree()
    .size([2 * Math.PI, radius])
    .separation(separation)(root);

  svg
    .append("g")
    .attr("fill", "none")
    .attr("stroke", stroke)
    .attr("stroke-opacity", strokeOpacity)
    .attr("stroke-linecap", strokeLinecap)
    .attr("stroke-linejoin", strokeLinejoin)
    .attr("stroke-width", strokeWidth)
    .selectAll("path")
    .data(root.links())
    .join("path")
    .attr(
      "d",
      d3
        .linkRadial()
        .angle((d) => d.x)
        .radius((d) => d.y)
    );

  const node = svg
    .append("g")
    .selectAll("a")
    .data(root.descendants())
    .join("a")
    .attr("xlink:href", link == null ? null : (d) => link(d.data, d))
    .attr("target", link == null ? null : linkTarget)
    .attr(
      "transform",
      (d) => `rotate(${(d.x * 180) / Math.PI - 90}) translate(${d.y},0)`
    );

  node
    .append("circle")
    .attr(
      "fill",
      (d) => window.colorSet[(d.depth - 1) % window.colorSet.length]
    )
    .attr("r", r)
    .select(function () {
      return this.parentNode;
    })
    .append("text")
    .text((d, i) => (d.data.membership_type == "vip" ? "✵" : ""))
    .attr("fill", "white")
    .attr("text-anchor", "middle")
    .attr("dy", `0.35em`)
    .attr("font-size", "13px");

  if (title != null) node.append("title").text((d) => title(d.data, d));

  if (L)
    node
      .append("text")
      .attr("transform", (d) => `rotate(${d.x >= Math.PI ? 180 : 0})`)
      .attr("dy", "0.32em")
      .attr("x", (d) => (d.x < Math.PI === !d.children ? 12 : -12))
      .attr("text-anchor", (d) =>
        d.x < Math.PI === !d.children ? "start" : "end"
      )
      .attr("paint-order", "stroke")
      .attr(
        "fill",
        (d) => window.colorSet[(d.depth - 1) % window.colorSet.length]
      )
      .attr("stroke", halo)
      .attr("stroke-width", haloWidth)
      .text((d, i) => L[i])
      .on("click", (d) => Account.downlineProfileModal(d.data.id));

  // if (L) node

  return svg.node();
}

export default function (container_selector, data_url) {
  var zoom = d3.zoom();

  d3.json(data_url).then(function (data) {
    document.querySelector(container_selector).innerHTML = "";
    var width = document.querySelector(container_selector).offsetWidth;
    var height = Math.max(400, window.innerHeight - 400);
    var wOffset = width / 2;
    var hOffset = height / 2;

    var zoom_box = d3
      .select(container_selector)
      .append("div")
      .attr("style", "overflow: visible");

    svg = zoom_box
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
      .attr("font-family", "sans-serif")
      .attr("font-size", 10)
      .append("g");

    zoom_box.call(
      zoom.on("zoom", function () {
        svg.attr("transform", d3.event.transform);
      })
    );

    zoom_box
      .call(zoom)
      .call(zoom.transform, d3.zoomIdentity.translate(wOffset, hOffset));

    var sizeFactor = 12;

    if (data.total_network_count < 50) {
      sizeFactor = 45;
    } else if (data.total_network_count < 100) {
      sizeFactor = 25;
    } else if (data.total_network_count < 200) {
      sizeFactor = 22;
    } else if (data.total_network_count < 500) {
      sizeFactor = 20;
    }

    Tree(data, {
      label: (d) => {
        var spaceIndex = d.name.indexOf(" ");

        if (spaceIndex == -1 || spaceIndex == d.name.length - 1) {
          return d.name;
        } else {
          return d.name.substring(0, spaceIndex + 2);
        }
      },
      title: (d) => d.name,
      width: data.total_network_count * sizeFactor,
      height: data.total_network_count * sizeFactor,
      margin: 100,
    });
  });
}
