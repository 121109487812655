window.AccountCreation = {
  validateEmail: function (email) {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  },
  checkEmail: function (el) {
    var email = el.value;
    const invalidFeedback = document.getElementById("email-invalid-feedback");
    const createAccountSubmit = document.getElementById(
      "create-account-submit"
    );

    if (!AccountCreation.validateEmail(email)) {
      el.classList.add("border-red-500");
      invalidFeedback.classList.remove("hidden");
      invalidFeedback.innerHTML = "Please enter a valid email address";
      createAccountSubmit.disabled = true;
      return;
    } else {
      createAccountSubmit.disabled = true;

      fetch(`/account/email/check?email=${email}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.result == "valid") {
            createAccountSubmit.disabled = false;
            el.classList.remove("border-red-500");
            el.classList.add("is-valid");
            invalidFeedback.classList.add("hidden");
          } else {
            el.classList.add("border-red-500");
            invalidFeedback.classList.remove("hidden");
            invalidFeedback.innerHTML = "This email has already been taken";
            createAccountSubmit.disabled = true;
          }
        })
        .catch(() => {
          el.classList.add("border-red-500");
          invalidFeedback.classList.remove("hidden");
          invalidFeedback.innerHTML = "Invalid email";
          createAccountSubmit.disabled = true;
        });
    }
  },
  validatePasswords: function () {
    var passwordInput = document.getElementById("customer_password");
    var confirmationInput = document.getElementById(
      "customer_password_confirmation"
    );
    var passwordFeedback = document.getElementById("password-invalid-feedback");
    var confirmationFeedback = document.getElementById(
      "confirmation-invalid-feedback"
    );
    var createAccountSubmit = document.getElementById("create-account-submit");

    if (passwordInput.value.length < 8) {
      passwordInput.classList.add("border-red-500");
      passwordFeedback.classList.remove("hidden");
      passwordFeedback.innerHTML =
        "Password must be at least 8 characters long";
      createAccountSubmit.disabled = true;
      return;
    }

    passwordInput.classList.remove("border-red-500");
    passwordInput.classList.add("is-valid");
    passwordFeedback.classList.add("hidden");

    if (passwordInput.value !== confirmationInput.value) {
      confirmationInput.classList.add("border-red-500");
      confirmationFeedback.classList.remove("hidden");
      confirmationFeedback.innerHTML = "Passwords must match";
      createAccountSubmit.disabled = true;
      return;
    }

    createAccountSubmit.disabled = false;
    confirmationInput.classList.remove("border-red-500");
    confirmationInput.classList.add("is-valid");
    confirmationFeedback.classList.add("hidden");
  },
};
