<template>
  <div>
    <h1 class="page-header">Secure Checkout</h1>

    <div v-if="errors.general">
      <div class="alert alert-danger">
        {{ errors.general }}
      </div>
    </div>

    <div
      class="w-full my-12 lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-12 xl:gap-x-16">
      <section class="lg:col-span-8">
        <form
          method="POST"
          action="/store/checkout"
          v-on:submit.prevent="submitForm()">
          <h2 class="my-3">Contact Details</h2>

          <div class="grid grid-cols-2 gap-y-3 gap-x-3 form-inputs">
            <div>
              <label for="first_name">First Name</label>
              <input
                type="text"
                name="first_name"
                id="first_name"
                v-model="customer.first_name"
                class="text-base form-control"
                required />
              <div v-if="errors.first_name" class="mt-1 text-sm text-red-500">
                {{ errors.first_name }}
              </div>
            </div>

            <div>
              <label for="last_name">Last Name</label>
              <input
                type="text"
                name="last_name"
                id="last_name"
                v-model="customer.last_name"
                class="text-base form-control"
                required />
              <div v-if="errors.last_name" class="mt-1 text-sm text-red-500">
                {{ errors.last_name }}
              </div>
            </div>

            <div>
              <label for="email">Email</label>
              <input
                type="text"
                name="email"
                id="email"
                v-model="customer.email"
                class="text-base form-control"
                required />
              <div v-if="errors.email" class="mt-1 text-sm text-red-500">
                {{ errors.email }}
              </div>
            </div>

            <div>
              <label for="phone_number">Phone Number</label>
              <input
                type="text"
                name="phone_number"
                id="phone_number"
                v-model="customer.phone_number"
                class="text-base form-control"
                required />
              <div v-if="errors.phone_number" class="mt-1 text-sm text-red-500">
                {{ errors.phone_number }}
              </div>
            </div>
          </div>

          <h2 class="my-3">Shipping Details</h2>

          <div class="grid grid-cols-2 gap-y-3 gap-x-3 form-inputs">
            <div>
              <label for="address_line_1">Address Line 1</label>
              <input
                type="text"
                name="address_line_1"
                id="address_line_1"
                v-model="address.line_1"
                class="text-base form-control"
                required />
              <div
                v-if="errors.address_line_1"
                class="mt-1 text-sm text-red-500">
                {{ errors.address_line_1 }}
              </div>
            </div>

            <div>
              <label for="address_line_2">Address Line 2 (optional)</label>
              <input
                type="text"
                name="address_line_2"
                id="address_line_2"
                v-model="address.line_2"
                class="text-base form-control" />
              <div
                v-if="errors.address_line_2"
                class="mt-1 text-sm text-red-500">
                {{ errors.address_line_2 }}
              </div>
            </div>

            <div>
              <label for="city">City</label>
              <input
                type="text"
                name="city"
                id="city"
                v-model="customer.city"
                class="text-base form-control"
                required />
              <div v-if="errors.city" class="mt-1 text-sm text-red-500">
                {{ errors.city }}
              </div>
            </div>

            <div>
              <label for="state">State</label>
              <input
                type="text"
                name="state"
                id="state"
                v-model="customer.state"
                class="text-base form-control"
                required />
              <div v-if="errors.state" class="mt-1 text-sm text-red-500">
                {{ errors.state }}
              </div>
            </div>

            <div>
              <label for="zip">Zip (optional)</label>
              <input
                type="text"
                name="zip"
                id="zip"
                v-model="customer.zip"
                class="text-base form-control" />
              <div v-if="errors.zip" class="mt-1 text-sm text-red-500">
                {{ errors.zip }}
              </div>
            </div>

            <div>
              <label for="country">Country</label>
              <select
                name="country"
                id="country"
                v-model="address.country"
                class="text-base form-control">
                <option value="AE">United Arab Emirates</option>
                <option value="PH">Philippines</option>
              </select>
            </div>
          </div>
        </form>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    errors: {},
    customer: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      address: "",
      city: "",
      state: "",
      zip: "",
    },
    address: {
      line_1: "",
      line_2: "",
      city: "",
      state: "",
      country: "AE",
    },
    credit_card: {
      card_number: "",
      expiry: "",
      cvv: "",
    },
  }),
  methods: {
    submitForm() {},
  },
};
</script>
