import ForceGraph from "force-graph";

export default function (containerID, graphData, opts = {}) {
  const el = document.getElementById(containerID);

  if (el == null) {
    return null;
  }

  opts["width"] ||= el.clientWidth;
  opts["height"] ||= 500;

  let darkMode = false;
  if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    darkMode = true;
  }

  const Graph = ForceGraph()(el)
    .nodeId("id")
    .nodeLabel((node) => node["label"])
    .enableNodeDrag(false)
    .enableZoomInteraction(false)
    .enablePanInteraction(false)
    .enablePointerInteraction(false)
    .width(opts["width"])
    .height(opts["height"])
    .nodeColor((node) => {
      return node["color"] || (darkMode ? "#FFFFFF" : "#000000");
    })
    .nodeRelSize(5)
    .linkColor(() => (darkMode ? "#555555" : "#DDDDDD"))
    .linkCurvature(
      (d: object) =>
        0.07 * // max curvature
        //   curve outwards from source, using gradual straightening within a margin of a few px
        Math.max(-1, Math.min(1, (d["source"].x - d["target"].x) / 5)) *
        Math.max(-1, Math.min(1, (d["target"].y - d["source"].y) / 5))
    )
    .nodeCanvasObjectMode(() => "before")
    .nodeCanvasObject((node: object, ctx, globalScale) => {
      const fontSize = 14 / globalScale;
      ctx.font = `${fontSize}px Sans-Serif`;
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillStyle = node["color"] || (darkMode ? "#FFFFFF" : "#000000");
      ctx.fillText(node["label"], node["x"], node["y"] + 14);
    })
    .cooldownTicks(0);

  Graph.graphData(graphData);
  Graph.zoomToFit(0, 50);

  return Graph;
}
