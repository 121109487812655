<template>
  <div class="flex flex-col gap-1 md:flex-row">
    <div>
      <input
        type="text"
        v-model="searchQuery"
        v-on:input="searchCustomers"
        v-bind:disabled="searching"
        onfocus="this.select()"
        placeholder="Search Binary Network"
        class="w-full p-1 mb-1 text-sm border dark:border-zinc-800" />

      <div v-if="searchQuery.length == 0" class="grid grid-cols-2 gap-1 mb-1">
        <button
          v-bind:class="{
            'w-full btn btn-sm rounded-sm': true,
            'btn-primary': leftPanelMode == 'uplines',
            'btn-outline-primary': leftPanelMode != 'uplines',
          }"
          v-on:click="leftPanelMode = 'uplines'">
          Path
        </button>
        <button
          v-bind:class="{
            'w-full btn btn-sm rounded-sm': true,
            'btn-primary': leftPanelMode == 'pending',
            'btn-outline-primary': leftPanelMode != 'pending',
          }"
          v-on:click="leftPanelMode = 'pending'">
          Pending ({{ insertableCustomers.length }})
        </button>
      </div>
      <div v-else class="mb-1">
        <button
          v-bind:class="{
            'w-full btn btn-sm rounded-sm btn-outline-primary': true,
          }"
          v-on:click="searchQuery = ''">
          Back
        </button>
      </div>

      <div
        id="binary-network-search"
        v-if="showSearchResults"
        style="max-height: 350px; overflow-y: auto">
        <div v-for="customer in searchResults">
          <div
            v-bind:class="{
              'border dark:border-zinc-800 p-2 mb-1 text-sm cursor-pointer': true,
              'bg-gray-100 hover:bg-gray-300 dark:bg-zinc-900 dark:hover:bg-zinc-800':
                customer.id != currentCustomerID,
              'bg-blue-500 text-white': customer.id == currentCustomerID,
            }"
            v-on:click="fetchSlice(customer.id)">
            {{ customer.full_name }}
          </div>
        </div>
        <div v-if="searching">
          <span class="text-xs animation-pulse">Searching...</span>
        </div>
        <div
          v-if="
            !searching &&
            searchQueryValid &&
            searchQuery.length > 0 &&
            searchResults.length == 0
          ">
          <span class="text-xs">No results found</span>
        </div>
      </div>

      <div
        id="binary-network-uplines"
        v-if="showUplines"
        style="max-height: 350px; overflow-y: auto">
        <div v-for="upline in uplines">
          <div
            class="text-sm text-blue-600 cursor-pointer hover:text-blue-800"
            v-on:click="fetchSlice(upline.id)">
            {{ upline.full_name }}
          </div>
          <div class="text-xs">&darr;</div>
        </div>
        <div v-if="nodes[0] && uplines.length > 0" class="text-sm">
          {{ nodes[0].name }}
        </div>
      </div>

      <div
        id="binary-network-pending"
        v-if="showPending"
        style="max-height: 350px; overflow-y: auto">
        <div v-for="customer in insertableCustomers">
          <div
            v-bind:class="{
              'border dark:border-zinc-800 p-2 mb-1 text-sm cursor-pointer': true,
              'bg-blue-500 text-white': insertingCustomerID == customer.id,
              'bg-gray-100 hover:bg-gray-300 dark:bg-zinc-900 dark:hover:bg-zinc-800':
                insertingCustomerID != customer.id,
              'opacity-50':
                insertingCustomerID != null &&
                insertingCustomerID != customer.id,
            }"
            v-on:click="toggleInsertingCustomerID(customer.id)">
            {{ customer.full_name }}
          </div>
        </div>
      </div>
    </div>

    <div class="overflow-x-auto">
      <div
        id="binary-network-graph"
        v-bind:class="{
          'border dark:border-zinc-800': true,
          'opacity-50': fetching,
        }"
        style="width: 600px; height: 350px; overflow: hidden">
        <svg viewBox="0 0 500 500">
          <g v-for="n in 7">
            <line
              v-bind:x1="nodeX(n - 1)"
              v-bind:y1="nodeY(n - 1)"
              v-bind:x2="nodeX((n - 1) * 2 + 1)"
              v-bind:y2="nodeY((n - 1) * 2 + 1)"
              v-bind:style="`stroke: ${lineStroke(
                n - 1,
                'left'
              )}; stroke-width: 1`" />
            <line
              v-bind:x1="nodeX(n - 1)"
              v-bind:y1="nodeY(n - 1)"
              v-bind:x2="nodeX((n - 1) * 2 + 2)"
              v-bind:y2="nodeY((n - 1) * 2 + 2)"
              v-bind:style="`stroke: ${lineStroke(
                n - 1,
                'right'
              )}; stroke-width: 1`" />
          </g>

          <g v-for="n in 8">
            <line
              v-if="nodes[n + 6]"
              v-bind:x1="nodeX(n + 6)"
              v-bind:y1="nodeY(n + 6)"
              v-bind:x2="nodeX(n + 6)"
              v-bind:y2="nodeY(n + 6) + 100"
              style="stroke: #dddddd; stroke-width: 1"
              stroke-dasharray="5,5" />
          </g>

          <g v-for="n in 15" v-bind:id="`node-group-${n - 1}`">
            <circle
              v-if="animationCheck(n - 1)"
              v-bind:cx="nodeX(n - 1)"
              v-bind:cy="nodeY(n - 1)"
              v-bind:r="nodeRadius"
              v-bind:style="{ fill: nodeFill(n - 1) }"
              v-bind:stroke-width="isInsertableNode(n - 1) ? 1 : 0"
              stroke="#000000"
              stroke-dasharray="5,5"
              v-bind:class="{
                'rotate-center cursor-pointer': isInsertableNode(n - 1),
              }"
              v-on:click="insertCustomerAtNode(n - 1)" />
            <text
              v-if="nodes[n - 1] && animationCheck(n - 1)"
              class="cursor-pointer binary-node-name"
              v-bind:x="nodeX(n - 1)"
              v-bind:y="nodeY(n - 1) + 18 + nodeTextOffset(n - 1)"
              text-anchor="middle"
              v-on:click="setRoot(n - 1)"
              style="font-family: Verdana, sans-serif; font-size: 9px"
              v-bind:fill="nameFillColor">
              {{ nodes[n - 1].name }}
            </text>
            <text
              v-if="loadingNodeIndex == n - 1"
              class="cursor-pointer binary-node-name animate-pulse"
              v-bind:x="nodeX(n - 1)"
              v-bind:y="nodeY(n - 1) + 4"
              text-anchor="middle">
              ⌛
            </text>
          </g>
        </svg>
      </div>

      <div class="flex justify-center gap-3 mt-3">
        <div>
          <i class="fa fa-circle" style="color: #248efb"></i>
          <span class="ml-1">Standard</span>
        </div>
        <div>
          <i class="fa fa-circle" style="color: #fbbf24"></i>
          <span class="ml-1">VIP</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["rootCustomerID", "routePrefix"],
  computed: {
    showSearchResults() {
      return this.searchQuery.length > 0;
    },
    showUplines() {
      return this.searchQuery.length == 0 && this.leftPanelMode == "uplines";
    },
    showPending() {
      return this.searchQuery.length == 0 && this.leftPanelMode == "pending";
    },
  },
  data: function () {
    return {
      fetching: false,
      searching: false,
      nodes: [],
      uplines: [],
      searchResults: [],
      searchCustomersDebounce: [],
      insertableCustomers: [],
      currentCustomerID: null,
      loadingNodeIndex: null,
      insertingCustomerID: null,
      searchQueryValid: false,
      searchQuery: "",
      leftPanelMode: "pending",
      nodeRadius: 8,
      levelSeparation: 75,
      animatingNodeIndex: -1,
      nameFillColor: "#000000",
      lineStrokeColor: "#DDDDDD",
      lineBlockedStrokeColor: "#DD7777",
    };
  },
  methods: {
    searchCustomers() {
      let self = this;
      if (self.searchCustomersDebounce) {
        window.clearTimeout(self.searchCustomersDebounce);
      }

      self.searchCustomersDebounce = window.setTimeout(function () {
        self.searching = true;
        self.searchQueryValid = false;

        fetch(
          `${self.routePrefix}/binary_network/search?query=${self.searchQuery}&root_customer_id=${self.rootCustomerID}`
        )
          .then((response) => response.json())
          .then((data) => {
            self.searching = false;
            self.searchQueryValid = true;
            self.searchResults = data;
          });
      }, 500);
    },
    lineStroke(index, direction) {
      if (!this.nodes[index]) {
        return this.lineStrokeColor;
      }
      if (
        this.nodes[index].block_spillover == "both" ||
        this.nodes[index].block_spillover == direction
      ) {
        return this.lineBlockedStrokeColor;
      }

      return this.lineStrokeColor;
    },
    parentIndex(index) {
      return Math.floor((index - 1) / 2);
    },
    nodeTextOffset(nodeIndex) {
      if (nodeIndex <= 6) {
        return 0;
      }
      if (nodeIndex % 2 == 0) {
        return 0;
      }

      return -30;
    },
    insertCustomerAtNode(nodeIndex) {
      let self = this;
      if (!self.insertingCustomerID) {
        return;
      }
      if (!self.isInsertableNode(nodeIndex)) {
        return;
      }
      if (self.nodes[nodeIndex]) {
        return;
      }
      if (!confirm("Are you sure you want to place this member here?")) {
        return;
      }

      let parentNodeIndex = Math.floor((nodeIndex - 1) / 2);
      let parentCustomerID = self.nodes[parentNodeIndex].id;
      let customerID = self.insertingCustomerID;

      let direction = nodeIndex % 2 == 0 ? "right" : "left";

      self.insertingCustomerID = null;
      self.loadingNodeIndex = nodeIndex;
      self.insertableCustomers = [];

      fetch(`${self.routePrefix}/binary_network/add_child`, {
        method: "POST",
        body: `parent_customer_id=${parentCustomerID}&child_customer_id=${customerID}&direction=${direction}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((data) => {
          self.fetchSlice(self.currentCustomerID || self.rootCustomerID);
          self.fetchPotentialChildren(self.rootCustomerID);
          self.loadingNodeIndex = null;
        })
        .catch((error) => {
          self.fetchSlice(self.currentCustomerID || self.rootCustomerID);
          self.fetchPotentialChildren(self.rootCustomerID);
          self.loadingNodeIndex = null;
        });
    },
    isInsertableNode(nodeIndex) {
      if (!this.insertingCustomerID) {
        return false;
      }
      if (nodeIndex == 0) {
        return false;
      }
      if (this.nodes[nodeIndex]) {
        return false;
      }

      let parentNodeIndex = this.parentIndex(nodeIndex);
      if (!this.nodes[parentNodeIndex]) {
        return false;
      }

      let direction = nodeIndex % 2 == 0 ? "right" : "left";
      let spilloverPossible =
        this.nodes[parentNodeIndex].ignore_spillover_preference ||
        (this.nodes[parentNodeIndex].block_spillover != "both" &&
          this.nodes[parentNodeIndex].block_spillover != direction);

      return spilloverPossible;
    },
    toggleInsertingCustomerID(customerID) {
      if (this.insertingCustomerID == customerID) {
        this.insertingCustomerID = null;
      } else {
        this.insertingCustomerID = customerID;
      }
    },
    animationCheck: function (i) {
      return this.animatingNodeIndex == -1 || this.animatingNodeIndex == i;
    },
    nodeX(nodeIndex) {
      if (nodeIndex == 0) {
        return this.xForIndexAndDepth(0, 0);
      } else if (nodeIndex >= 1 && nodeIndex <= 2) {
        return this.xForIndexAndDepth(nodeIndex - 1, 1);
      } else if (nodeIndex >= 3 && nodeIndex <= 6) {
        return this.xForIndexAndDepth(nodeIndex - 3, 2);
      } else if (nodeIndex >= 7 && nodeIndex <= 14) {
        return this.xForIndexAndDepth(nodeIndex - 7, 3);
      }
    },
    nodeY(nodeIndex) {
      if (nodeIndex == 0) {
        return this.yForDepth(0);
      } else if (nodeIndex >= 1 && nodeIndex <= 2) {
        return this.yForDepth(1);
      } else if (nodeIndex >= 3 && nodeIndex <= 6) {
        return this.yForDepth(2);
      } else if (nodeIndex >= 7 && nodeIndex <= 14) {
        return this.yForDepth(3);
      }
    },
    nodeFill(nodeIndex) {
      if (this.nodes[nodeIndex] === undefined) {
        return "#DDDDDD";
      }

      if (this.nodes[nodeIndex].membership_type == "standard") {
        return "#248efb";
      } else if (this.nodes[nodeIndex].membership_type == "vip") {
        return "#fbbf24";
      }

      return "#222222";
    },
    xForIndexAndDepth(index, depth) {
      margin = 0;
      separation = 0;

      if (depth == 0) {
        return 250;
      } else if (depth == 1) {
        margin = 118;
        separation = 256;
      } else if (depth == 2) {
        margin = 56;
        separation = 128;
      } else if (depth == 3) {
        margin = 24;
        separation = 64;
      }

      return margin + index * separation;
    },
    yForDepth(depth) {
      return 30 + depth * this.levelSeparation;
    },
    fetchPotentialChildren(customerID) {
      let self = this;
      fetch(
        `${self.routePrefix}/binary_network/potential_children?customer_id=${self.rootCustomerID}`
      )
        .then((response) => response.json())
        .then((data) => {
          self.insertableCustomers = data;

          if (self.insertableCustomers.length == 0) {
            self.leftPanelMode = "uplines";
          }
        });
    },
    fetchSlice(customerID, callback) {
      let self = this;
      self.fetching = true;
      fetch(
        `${self.routePrefix}/binary_network/slice?customer_id=${customerID}&root_customer_id=${self.rootCustomerID}`
      )
        .then((response) => response.json())
        .then((data) => {
          self.fetching = false;
          self.currentCustomerID = customerID;

          let customers = data.network;
          self.animatingNodeIndex = -1;
          self.uplines = data.uplines;

          self.nodes[0] = customers[0];

          self.nodes[1] = customers.find(
            (c) => c.parent_id == self.nodes[0].id && c.direction == "left"
          );
          self.nodes[2] = customers.find(
            (c) => c.parent_id == self.nodes[0].id && c.direction == "right"
          );

          self.nodes[3] = customers.find(
            (c) =>
              self.nodes[1] &&
              c.parent_id == self.nodes[1].id &&
              c.direction == "left"
          );
          self.nodes[4] = customers.find(
            (c) =>
              self.nodes[1] &&
              c.parent_id == self.nodes[1].id &&
              c.direction == "right"
          );
          self.nodes[5] = customers.find(
            (c) =>
              self.nodes[2] &&
              c.parent_id == self.nodes[2].id &&
              c.direction == "left"
          );
          self.nodes[6] = customers.find(
            (c) =>
              self.nodes[2] &&
              c.parent_id == self.nodes[2].id &&
              c.direction == "right"
          );

          self.nodes[7] = customers.find(
            (c) =>
              self.nodes[3] &&
              c.parent_id == self.nodes[3].id &&
              c.direction == "left"
          );
          self.nodes[8] = customers.find(
            (c) =>
              self.nodes[3] &&
              c.parent_id == self.nodes[3].id &&
              c.direction == "right"
          );
          self.nodes[9] = customers.find(
            (c) =>
              self.nodes[4] &&
              c.parent_id == self.nodes[4].id &&
              c.direction == "left"
          );
          self.nodes[10] = customers.find(
            (c) =>
              self.nodes[4] &&
              c.parent_id == self.nodes[4].id &&
              c.direction == "right"
          );
          self.nodes[11] = customers.find(
            (c) =>
              self.nodes[5] &&
              c.parent_id == self.nodes[5].id &&
              c.direction == "left"
          );
          self.nodes[12] = customers.find(
            (c) =>
              self.nodes[5] &&
              c.parent_id == self.nodes[5].id &&
              c.direction == "right"
          );
          self.nodes[13] = customers.find(
            (c) =>
              self.nodes[6] &&
              c.parent_id == self.nodes[6].id &&
              c.direction == "left"
          );
          self.nodes[14] = customers.find(
            (c) =>
              self.nodes[6] &&
              c.parent_id == self.nodes[6].id &&
              c.direction == "right"
          );

          if (callback) {
            callback();
          }
        })
        .catch((x) => {});
    },
    setRoot(i) {
      if (i == 0) {
        return;
      }
      this.animatingNodeIndex = i;

      let el = document.getElementById(`node-group-${i}`);
      let xTranslate = 250 - this.nodeX(i);
      let yTranslate = 30 - this.nodeY(i);

      el.style.setProperty("transition", "all 1s");
      el.setAttribute("transform", `translate(${xTranslate},${yTranslate})`);

      this.currentCustomerID = this.nodes[i].id;
      this.fetchSlice(this.nodes[i].id, function () {
        el.style.setProperty("transition", "none");
        el.removeAttribute("transform");
      });
    },
  },
  mounted() {
    this.fetchSlice(this.rootCustomerID);
    this.fetchPotentialChildren(this.rootCustomerID);

    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      this.nameFillColor = "#FFFFFF";
      this.lineStrokeColor = "#555555";
      this.lineBlockedStrokeColor = "#AA5555";
    }
  },
};
</script>
