import flatpickr from "flatpickr";
import moment from "moment";
import MicroModal from "micromodal";
import RadialTree from "../components/radial_tree";
import Cookies from "js-cookie";
import TomSelect from "tom-select";
import { createApp } from "vue";
import PerfumeCustomizer from "../components/customized_perfume/index.vue";
import ExecutiveRankRequirements from "../components/executive_rank_requirements.vue";
import RewardsProgramGraphs from "./rewards_program_graphs";
import CheckoutForm from "../components/store/checkout_form.vue";
import SimpleLightbox from "simplelightbox/dist/simple-lightbox.esm";

window.initializePerfumeCustomizer = function (containerSelector) {
  createApp(PerfumeCustomizer).mount(containerSelector);
};

window.colorSet = [
  "#1f77b4",
  "#17becf",
  "#ff7f0e",
  "#d83f6e",
  "#a1d700",
  "#d62728",
  "#a0e7e5",
  "#9467bd",
  "#e377c2",
  "#17becf",
];

const Highcharts = require("highcharts");
Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
  colors: colorSet,
});

window.loadRewardsSummary = function (date) {
  document.querySelector("#rewards-summary table").classList.add("opacity-30");
  fetch(`/account/rewards/summary?date=${date}`)
    .then((response) => response.text())
    .then((data) => {
      document.querySelector("#rewards-summary").innerHTML = data;
      document.querySelector("#rewards-summary").classList.remove("opacity-30");
    });
};

window.refreshChartData = function (e, form, chartID) {
  e.preventDefault();
  var data = new URLSearchParams(new FormData(form)).toString();

  var chart = Chartkick.charts[chartID];
  chart.updateData(form.getAttribute("action") + "?" + data);

  return false;
};

window.confirmPasswords = function () {
  if (
    document.querySelector("#customer_password").value.length > 0 &&
    document.querySelector("#customer_password").value ==
      document.querySelector("#customer_password_confirmation").value
  ) {
    document.querySelector("#btn-set-password").removeAttribute("disabled");
    document.querySelector("#password-confirmation-error").style.display =
      "none";
  } else {
    document
      .querySelector("#btn-set-password")
      .setAttribute("disabled", "disabled");
    document.querySelector("#password-confirmation-error").style.display =
      "block";
  }
};

function reportPhoneConversion() {
  var defaultTag = "AW-753055537/VuKNCPb6svUBELHuiucC";

  gtag("event", "conversion", {
    send_to: window.phoneConversionTagOverride || defaultTag,
  });
}

function reportEmailConversion() {
  var defaultTag = "AW-753055537/xS-RCIbyxfUBELHuiucC";

  gtag("event", "conversion", {
    send_to: window.emailConversionTagOverride || defaultTag,
  });
}

function onPageLoadStuff() {
  MicroModal.init();

  if (document.getElementById("top-nav-toggle")) {
    document.getElementById("top-nav-toggle").addEventListener("click", () => {
      document.getElementById("top-nav-container").classList.toggle("open");
      document.getElementById("center-nav").classList.toggle("hidden");
      document.getElementById("cart-nav").classList.toggle("hidden");
    });
  }

  if (document.getElementById("top-nav-hamburger")) {
    document
      .getElementById("top-nav-hamburger")
      .addEventListener("click", () => {
        document.getElementById("top-nav-container").classList.toggle("open");
        document.getElementById("center-nav").classList.toggle("hidden");
        document.getElementById("cart-nav").classList.toggle("hidden");
      });
  }

  // Find all elements with the data-lightbox attribute present
  if (document.querySelectorAll("[data-lightbox]").length > 0) {
    const lightbox = new SimpleLightbox(".product-main-image a");
    const lightbox_2 = new SimpleLightbox(".product-image a");
  }

  document.querySelectorAll(".select2").forEach((el) => {
    let settings = {
      create: el.classList.contains("select2-tags"),
      createOnBlur: el.classList.contains("select2-tags"),
      persist: false,
    };
    new TomSelect(el, settings);
  });

  document.querySelectorAll(".dropdown").forEach(function (dropdown) {
    dropdown.addEventListener("click", function () {
      this.classList.toggle("open");
    });
  });

  document
    .querySelectorAll(".tel-link, .whatsapp-link")
    .forEach(function (telLink) {
      telLink.addEventListener("click", reportPhoneConversion);
    });

  document.querySelectorAll(".email-link").forEach(function (emailLink) {
    emailLink.addEventListener("click", reportEmailConversion);
  });

  if (document.querySelector("#main-notice")) {
    window.setTimeout(function () {
      document.querySelector("#main-notice").style.display = "none";
    }, 5000);
  }

  document.querySelectorAll(".downline-link").forEach(function (link) {
    link.addEventListener("click", Account.showDownlineProfile);
  });

  document.querySelectorAll(".radial-tree").forEach(function (tree) {
    RadialTree(`#${tree.id}`, tree.getAttribute("data-href"));
  });

  document.querySelectorAll(".datepicker").forEach(function (datepicker) {
    flatpickr(datepicker, {
      altInput: true,
      altFormat: "F j, Y",
      dateFormat: "Y-m-d",
    });
  });

  if (document.getElementById("perfume-customizer")) {
    initializePerfumeCustomizer("#perfume-customizer");
  }

  if (document.getElementById("sales-rewards-graph-container")) {
    RewardsProgramGraphs.init();
  }

  if (document.getElementById("executive-rank-requirements")) {
    createApp(ExecutiveRankRequirements).mount("#executive-rank-requirements");
  }

  if (document.getElementById("checkout-form")) {
    createApp(CheckoutForm).mount("#checkout-form");
  }

  const timerContainers = document.querySelectorAll(".countdown-timer");
  if (timerContainers.length > 0) {
    window.setInterval(() => {
      timerContainers.forEach(function (timerContainer) {
        const secondsRemaining = timerContainer.getAttribute(
          "data-seconds-remaining"
        );
        const newSecondsRemaining = secondsRemaining - 1;
        timerContainer.setAttribute(
          "data-seconds-remaining",
          newSecondsRemaining
        );
        const formattedTime = moment
          .utc(newSecondsRemaining * 1000)
          .format("HH:mm:ss");
        timerContainer.innerHTML = formattedTime;
      });
    }, 1000);
  }
}

document.addEventListener("DOMContentLoaded", onPageLoadStuff);

window.copyLinkToClipboard = function (input_id) {
  var copyText = document.getElementById(input_id);

  /* Select the text field */
  copyText.select();
  copyText.setSelectionRange(0, 99999); /*For mobile devices*/

  /* Copy the text inside the text field */
  document.execCommand("copy");

  /* Alert the copied text */
  document
    .querySelector("#" + input_id + "-copy-success")
    .classList.remove("hidden");
};

window.submitContactForm = function (event) {
  event.preventDefault();

  var thanksMessage =
    '<div class="text-lg text-center">Thanks for contacting us.<br /> We\'ll get back to you as soon as possible!</div>';
  var whiteThanksMessage =
    '<div class="text-white text-lg text-center">Thanks for contacting us.<br /> We\'ll get back to you as soon as possible!</div>';

  event.target.insertAdjacentHTML(
    "beforeend",
    '<input type="hidden" name="details" value="evaporation" />'
  );
  fetch("/contact", {
    method: "POST",
    body: new FormData(event.target),
  })
    .then(() => {
      if (event.target.querySelector(".text-white")) {
        event.target.innerHTML = whiteThanksMessage;
      } else {
        event.target.innerHTML = thanksMessage;
      }

      try {
        gtag("event", "inquiry");

        var adwordsTag = event.target.dataset.adwords;
        if (adwordsTag && adwordsTag.length > 0) {
          gtag("event", "conversion", { send_to: adwordsTag });
        }
      } catch (e) {
        // Gtag is missing for some reason
      }
    })
    .catch((e) => {
      alert("There was an error while processing your form submission.");
    });
};
