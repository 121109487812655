<template>
  <div>
    <div class="grid md:grid-cols-2 grid-cols-1">
      <div>
        <svg
          class="w-full"
          viewBox="0 0 200 100"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M 100 20 L 50 80"
            :stroke="linkStroke"
            stroke-width="1"
            fill="none"
            v-if="step >= 2"></path>
          <path
            d="M 100 20 L 150 80"
            :stroke="linkStroke"
            stroke-width="1"
            fill="none"
            v-if="step >= 4"></path>

          <circle cx="100" cy="20" r="6" fill="#20a4f3"></circle>
          <text
            x="100"
            y="33"
            text-anchor="middle"
            dy=".3em"
            fill="#20a4f3"
            style="font-size: 9px; font-weight: 600">
            Alex
          </text>

          <circle
            cx="50"
            cy="80"
            r="6"
            fill="#f6ae2d"
            v-if="step >= 2"></circle>
          <text
            x="50"
            y="93"
            text-anchor="middle"
            dy=".3em"
            fill="#f6ae2d"
            style="font-size: 9px; font-weight: 600"
            v-if="step >= 2">
            Bob
          </text>
          <Transition name="slide-up-fade-out">
            <text
              x="75"
              y="20"
              text-anchor="middle"
              dy=".3em"
              fill="#20a4f3"
              style="font-size: 9px; font-weight: 600"
              v-if="step >= 3 && step < 6">
              60 pts
            </text>
          </Transition>

          <circle
            cx="150"
            cy="80"
            r="6"
            fill="#f6ae2d"
            v-if="step >= 4"></circle>
          <text
            x="150"
            y="93"
            text-anchor="middle"
            dy=".3em"
            fill="#f6ae2d"
            style="font-size: 9px; font-weight: 600"
            v-if="step >= 4">
            Charlie
          </text>
          <Transition name="slide-up-fade-out">
            <text
              x="125"
              y="20"
              text-anchor="middle"
              dy=".3em"
              fill="#20a4f3"
              style="font-size: 9px; font-weight: 600"
              v-if="step == 5">
              60 pts
            </text>
          </Transition>

          <Transition name="fade">
            <text
              x="100"
              y="45"
              text-anchor="middle"
              dy=".3em"
              fill="#84cc16"
              style="font-size: 10px; font-weight: 600"
              v-if="step == 6">
              + 120 pts
            </text>
          </Transition>
        </svg>
      </div>
      <div>
        <div
          class="border p-5 my-5 rounded-md bg-zinc-100 dark:border-zinc-800 dark:bg-zinc-900">
          <div style="min-height: 140px">
            <div v-if="step == 1">
              <span class="font-bold" style="color: #20a4f3">Alex</span>
              registers & purchases a VIP package
            </div>
            <div v-if="step == 2">
              <span class="font-bold" style="color: #20a4f3">Alex</span> adds
              <span class="font-bold" style="color: #f6ae2d">Bob</span> (VIP
              member) to his INFINITE network on the left side. This gives Alex
              60 points on the left side.
            </div>
            <div v-if="step == 3">
              <span class="font-bold" style="color: #20a4f3">Alex</span> now has
              <span class="font-bold" style="color: #20a4f3">60 points</span> on
              the left side waiting to pair.
            </div>
            <div v-if="step == 4">
              <span class="font-bold" style="color: #20a4f3">Alex</span> adds
              <span class="font-bold" style="color: #f6ae2d">Charlie</span> (VIP
              member) to his INFINITE network on the right side. This gives Alex
              <span class="font-bold" style="color: #20a4f3">60 points</span> on
              the right side.
            </div>
            <div v-if="step == 5">
              <span class="font-bold" style="color: #20a4f3">Alex</span> now has
              <span class="font-bold" style="color: #20a4f3">60 points</span> on
              both sides ready to pair.
            </div>
            <div v-if="step == 6">
              <span class="font-bold" style="color: #20a4f3">Alex</span> pairs
              the points on his left & right for a total payout of
              <span class="font-bold" style="color: #20a4f3">120 points</span>!
            </div>
          </div>

          <div
            class="flex gap-1 justify-center text-xl text-zinc-600 dark:text-zinc-300">
            <div class="p-2 cursor-pointer" @click="prevStep()">
              <i class="fa fa-backward-step"></i>
            </div>
            <div class="p-2 cursor-pointer" @click="play()">
              <i class="fa fa-play"></i>
            </div>
            <div class="p-2 cursor-pointer" @click="pause()">
              <i class="fa fa-pause"></i>
            </div>
            <div class="p-2 cursor-pointer" @click="nextStep()">
              <i class="fa fa-forward-step"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      step: 1,
      darkMode: false,
      linkStroke: "#CCCCCC",
      stepInterval: null,
    };
  },
  methods: {
    play() {
      this.stepInterval ||= window.setInterval(() => {
        this.nextStep();
      }, 7000);
    },
    pause() {
      window.clearInterval(this.stepInterval);
    },
    nextStep() {
      if (this.step < 6) {
        this.step++;
      } else {
        this.step = 1;
      }
    },
    prevStep() {
      if (this.step > 1) {
        this.step--;
      } else {
        this.step = 6;
      }
    },
  },
  mounted() {
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      this.darkMode = true;
      this.linkStroke = "#444444";
    }

    this.play();
  },
};
</script>
